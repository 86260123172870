<template>
    <div
      class="customer_manager"
      v-loading="loading"
      element-loading-text="加载中..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="top_card">
        <el-form>
          <el-form-item label="所在地区：">
            <CitySelect
              ref="child"
              province="贵州省"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>
          <el-form-item label="预算费用：">
            <div class="radio_group">
              <el-radio-group v-model="queryInfo.isRequirement" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button label="无线索">0-15万元</el-radio-button>
                <el-radio-button label="有潜在线索">15-50万元</el-radio-button>
                <el-radio-button label="有明确线索">50-75万元</el-radio-button>
                <el-radio-button label="有需求">75-100万元</el-radio-button>
                <el-radio-button label="100万元以上">100万元以上</el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="走访时间：">
            <el-date-picker
              v-model="timelist"
              type="daterange"
              @change="valtime"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
  
          <div class="row" style="justify-content: space-between">
            <div class="btn_list">
              <el-button plain @click="exportDiagnoseGroup">批量导出</el-button>
              <div>
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                >
                  全选
                </el-checkbox>
              </div>
            </div>
  
            <!-- 右边搜索 -->
            <div class="search_right">
              <el-input
                placeholder="请输入需求描述、企业名称、客户经理"
                v-model="queryInfo.keyword"
                class="input-with-select"
                clearable
                @change="search()"
                @input="handleInput"
              >
                <el-button slot="append" @click="search" type="primary">搜索</el-button>
              </el-input>
            </div>
          </div>
        </el-form>
      </div>
      <div class="row">
        <div class="row_txt">
          <span>当前条件下共查出</span>
          <span class="color1">{{ total }}</span>
          <span>条走访需求</span>
        </div>
        <div class="sort">
          <span @click="handleSorts(sortVals)">按推送客户经理数</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="isActives === 'up' ? 'top_active' : ''"
              @click="handleSorts('up')"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="isActives === 'down' ? 'bottom_active' : ''"
              @click="handleSorts('down')"
            ></div>
          </div>
        </div>
        <div class="sort">
          <span @click="handleSort(sortVals)">按推送企业数</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="isActive === 'up' ? 'top_active' : ''"
              @click="handleSort('up')"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="isActive === 'down' ? 'bottom_active' : ''"
              @click="handleSort('down')"
            ></div>
          </div>
        </div>
      </div>
      <div class="card_list">
        <div class="card_item" v-for="(item, index) in list" :key="index">
          <div class="card_left">
            <div class="item_checkbox">
              <el-checkbox
                @change="latechange($event, item)"
                v-model="item.checked1"
                label=""
              ></el-checkbox>
            </div>
  
            <div class="img_box">
              <img v-if="item.images" :src="item.images" alt="" />
              <img v-else src="../../../assets/image/zfxq1.png" alt="" />
            </div>
            <div class="info_box">
              <div class="flex">
                <div class="enterprise_name" @click="toEnterpriseDetails(item)">
                  {{ item.title }}
                </div>
              </div>
  
              <div class="margin_row">
                <div>
                  <span>所属企业：{{ item.companyFullName }}</span>
                </div>
                <div class="city_margin">
                  <span>{{ item.city }}</span>
                  <span v-if="item.district">-{{ item.district }}</span>
                </div>
              </div>
              <div class="flex">
                <span>预算费用：15万元</span>
                <!-- <span class="enterprise_txt">{{ item.briefIntroduction }}</span> -->
                <!-- <span class="enterprise_txt" v-html="item.requirementDescribe"></span> -->
              </div>
            </div>
            
            
          </div>
          <div class="card_right">
            <div class="btn_box">
              <div class="btn_list">
                <div class="set_manager">
                  <div class="middle_icon_box">
                    <img v-if="item.customerHead" :src="item.customerHead" alt="" />
                    <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
                  </div>
                  <span class="manager_name"> {{ item.customerName }} </span>
                </div>
                <span @click="toEnterpriseDetails(item)" class="color1">详情</span>
              </div>
              <div class="time_txt">
                <span>推送时间：{{ item.createTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </template>
      
      <script>
  import CitySelect from '@/components/CitySelect.vue'
  
  import { loglistPage, serviceexport } from '@/api/plan.js'
  const defaultQueryInfo = Object.freeze({
    city: '贵阳市',
    district: '',
    endTime: '',
    isRequirement: '',
    keyword: '',
    orderByParam: 2,
    pageNum: 1,
    pageSize: 10,
    startTime: ''
  })
  
  export default {
    name: 'service',
    components: { CitySelect },
    data() {
      return {
        sortVal: 'up', // 排序点击事件的传参
        isActive: '', // 排序图标样式
        sortVals: 'up', // 排序点击事件的传参
        isActives: '', // 排序图标样式
        checkAll: false,
        loading: false,
        isIndeterminate: false,
        ids: [],
        timelist: [],
        queryInfo: { ...defaultQueryInfo }, // 企业查询一览
        list: [], // 企业列表
        total: 0 // 企业总条数
      }
    },
    created() {
      this.search()
    },
    computed: {},
    methods: {
      valtime(val) {
        if (val) {
          this.queryInfo.startTime = val[0]
          this.queryInfo.endTime = val[1]
        } else {
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
        this.search()
      },
  
      changeVal(val) {
        if (!val) {
          this.queryInfo.segmentedIndustry = ''
          this.search()
          return
        }
        this.options.forEach((el) => {
          if (el.industryName == val) {
            // console.log(el);
            if (el.children) {
              this.childoptions = el.children
            } else {
              this.childoptions = []
              this.queryInfo.segmentedIndustry = ''
            }
          }
        })
        this.search()
      },
  
      async search() {
        this.loading = true
  
        const res = await loglistPage(this.queryInfo)
  
        if (res.resultCode == 200) {
          this.list = res.data.list
  
          this.total = res.data.total
          this.loading = false
          if (this.list) {
            this.handleCheckAllChange(false)
          }
        } else {
          this.$message.warning('获取数据失败!')
          this.loading = false
        }
      },
  
      handleInput(value) {
        this.queryInfo.keys = value.replace(/\s+/g, '')
      },
      //排序
      handleSort(val) {
        this.isActive = val
        if (val === 'up') {
          this.queryInfo.orderByParam = 1
          this.sortVal = 'down'
          this.search()
        } else {
          this.queryInfo.orderByParam = 2
          this.sortVal = 'up'
          this.search()
        }
      },
      //排序
      handleSorts(val) {
        this.isActives = val
        if (val === 'up') {
          this.queryInfo.orders = 5
          this.sortVals = 'down'
          this.search()
        } else {
          this.queryInfo.orders = 4
          this.sortVals = 'up'
          this.search()
        }
      },
      async exportDiagnoseGroup() {
        this.loading = true
        let ids = this.ids
        if(ids.length){
          this.queryInfo.allIn=1
        }else{
          this.queryInfo.allIn=0
        }
        const query = { ...this.queryInfo, ids }
        delete query.pageNum
        delete query.pageSize
        const res = await serviceexport(query)
        if (res) {
          this.loading = false
          let blob = new Blob([res])
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载'链接
          let nowdata = new Date()
          let year = nowdata.getFullYear()
          let month = nowdata.getMonth() + 1
          let date = nowdata.getDate()
          let nowtime = year + '-'
          if (month < 10) {
            nowtime += '0'
          }
          nowtime += month + '-'
          if (date < 10) {
            nowtime += '0'
          }
          nowtime += date
  
          a.download = `${nowtime} 服务记录.xlsx` //指定下载文件名
          a.click() //触发下载
          window.URL.revokeObjectURL(a.href) //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a) //释放标签
          }
        } else {
          this.loading = false
          this.$message.warning('下载失败!')
        }
      },
      handleCheckAllChange(bol) {
        //bol为布尔值，true是选中，false是取消
        if (bol) {
          this.list.forEach((item) => {
            //orgCompanyId
            this.ids.push(item.id)
            this.$set(item, 'checked1', true)
          })
        } else {
          this.list.forEach((item) => {
            this.ids = []
            this.checkAll = false
            this.$set(item, 'checked1', false)
          })
        }
  
        this.isIndeterminate = false
      },
      // 多选框选中数据
      latechange(bol, row) {
        // orgCompanyId
        const index = this.ids.indexOf(row.id)
        if (index == -1) {
          //orgCompanyId
          this.ids.push(row.id)
        } else {
          this.ids.splice(index, 1)
        }
        if (bol) {
          if (this.ids.length === this.list.length) {
            this.isIndeterminate = false
            this.checkAll = true
          } else {
            this.isIndeterminate = true
            this.checkAll = false
          }
        } else {
          if (this.ids.length < 1) {
            this.isIndeterminate = false
            this.checkAll = false
          } else {
            this.isIndeterminate = true
            this.checkAll = false
          }
        }
      },
      //监听pagesize变化
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.search()
      },
      //监听页码变化
      handleCurrentChange(newCurrent) {
        this.queryInfo.pageNum = newCurrent
        this.search()
      },
  
      //返回区/县
      searchDistrict(val) {
        this.queryInfo.district = val
        this.search()
      },
      toEnterpriseDetails(item) {
        this.$router.push({
          path: '/service/requirementinfo',
          query: {
            id: item.id
          }
        })
      },
      // 排序
      sortChange(val) {
        if (val.prop === 'companyCount') {
          if (val.order === 'ascending') {
            this.managerQueryInfo.orders = 2
          } else if (val.order === 'descending') {
            this.managerQueryInfo.orders = 3
          } else {
            this.managerQueryInfo.orders = 1
          }
        } else if (val.prop === 'loginTime') {
          if (val.order === 'ascending') {
            this.managerQueryInfo.orders = 4
          } else if (val.order === 'descending') {
            this.managerQueryInfo.orders = 5
          } else {
            this.managerQueryInfo.orders = 1
          }
        }
        this.searchCustomerManager()
      }
    }
  }
  </script>
      
      <style lang="less" scoped>
  ::v-deep .el-radio-button .el-radio-button__inner {
    border-left: 1px solid #fff;
    border-radius: 4px;
    box-shadow: none !important;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #448aff;
    border-color: #448aff;
    box-shadow: -1px 0 0 0 #448aff;
    border-radius: 4px;
  }
  ::v-deep .radio_group .el-radio-button__inner {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #fff;
    font-weight: 500;
    border-left: 0;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    position: relative;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 9px 15px !important;
    font-size: 14px;
    border-radius: 0;
  }
  .customer_manager {
    min-height: calc(100% - 0px);
    overflow: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
  }
  
  .new_page {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    border-top: #fafafa 1px solid;
    text-align: center;
  }
  .search_right {
    width: 460px;
    margin-right: 20px;
    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }
  .check {
    margin-left: auto;
    margin-right: 30px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 12px !important;
  }
  .btn_list {
    display: flex;
    align-items: center;
    justify-content: center;
    .set_manager {
      display: flex;
      align-items: center;
      min-width: 120px;
      margin-right: 0px;
      .middle_icon_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #f4f4f4;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 35px;
          height: 35px;
        }
        i {
          font-size: 16px;
          color: #c9c9c9;
        }
      }
      .manager_name,
      .wait_set {
        cursor: pointer;
        font-size: 16px;
      }
    }
    .el-button {
      margin-right: 10px;
    }
    span {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .row {
    display: flex;
    align-items: center;
    font-size: 14px;
   
  }
  .city_margin {
      margin-left: 10px;
    }
  .icon_box {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    img {
      width: 16px;
      height: 16px;
    }
    i {
      font-size: 16px;
    }
  }
  .el-radio-button ::v-deep .el-radio-button__inner {
    border-radius: 4px !important;
    border: 0 !important;
  }
  .row_txt {
    font-size: 16px;
  }
  .sort {
    display: flex;
    align-items: center;
    margin-left: 20px;
    span {
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }
    .sort_icon {
      cursor: pointer;
      display: flex;
      flex-flow: column;
      height: auto;
      i {
        width: 14px;
        height: 14px;
        color: #dbd6d6;
        font-size: 14px;
      }
      .isActive {
        color: #8a8888 !important;
      }
    }
  }
  
  .top_card {
    display: flex;
    flex-flow: column;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  
  .card_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    min-height: calc(100vh - 480px);
    .card_item {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 10px 20px;
      box-sizing: border-box;
      position: relative;
      .card_left {
        display: flex;
        align-items: center;
        padding-top: 10px;
        box-sizing: border-box;
        width: 650px;
        margin-right: 20px;
        position: relative;
        .isRequirement {
          background: #448aff;
          color: #fff;
          font-size: 14px;
          height: 20px;
          padding: 3px 5px;
          min-width: 50px;
          border-radius: 10px 10px 10px 0px;
          text-align: center;
          line-height: 20px;
        }
  
        .isRequirement_false {
          background: #8679ff;
          color: #fff;
          font-size: 14px;
          padding: 3px 5px;
          height: 20px;
          min-width: 50px;
          border-radius: 10px 10px 10px 0px;
          text-align: center;
          line-height: 20px;
        }
        .isRequirement_false1 {
          background: #ff8c06;
          color: #fff;
          font-size: 14px;
          padding: 3px 5px;
          height: 20px;
          min-width: 50px;
          border-radius: 10px 10px 10px 0px;
          text-align: center;
          line-height: 20px;
        }
        .isRequirement_false2 {
          background: #1fd88e;
          color: #fff;
          font-size: 14px;
          padding: 3px 5px;
          height: 20px;
          min-width: 50px;
          border-radius: 10px 10px 10px 0px;
          text-align: center;
          line-height: 20px;
        }
        .po_isRequirement {
          position: absolute;
          left: 15px;
          top: 0px;
        }
        .item_checkbox {
          line-height: 36px;
          margin: 0px 10px;
        }
        .img_box {
          width: 130px;
          height: 88px;
          margin-right: 10px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          img {
            width: 130px;
            height: 88px;
          }
        }
        .info_box {
          display: flex;
          justify-content: space-between;
          flex-flow: column;
  
          .margin_row {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 15px 0px;
          }
          .flex{
            font-size: 14px;
          }
          .enterprise_txt {
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }
          .enterprise_name {
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }
          .enterprise_name:hover {
            color: #448aff;
          }
        }
      }
      .card_right {
        flex: 1;
        display: flex;
        .box_list {
          display: flex;
          align-items: center;
          .item {
            cursor: pointer;
            display: flex;
            flex-flow: column;
            justify-content: center;
            text-align: center;
            margin-right: 30px;
            .num {
              font-weight: bold;
              font-size: 22px;
              color: #448aff;
              margin-bottom: 10px;
            }
            .txt {
              font-size: 15px;
              color: #151515;
            }
          }
        }
        .btn_box {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          margin-left: auto;
          margin-right: 10px;
          .time_txt {
            margin-top: 10px;
            color: #999999;
            font-size: 14px;
          }
          span:hover {
            opacity: 0.7;
          }
          .divs {
            margin-right: 10px;
          }
  
          .btn_list span {
            font-size: 15px;
          }
        }
      }
    }
  }
  
  .color1 {
    color: #4e93fb;
    font-size: 16px;
  }
  .color2 {
    color: #fd5469;
    font-size: 16px;
  }
  .color3 {
    color: #fc8b39;
    font-size: 16px;
  }
  </style>
      